@import "src/styles/variables";

.container {
  display: flex;
  padding: 4px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 2px solid $borderColor;
  background: transparent;
}
