@import "src/styles/variables";

.container {
  height: 204px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .elementsContainer {
    position: absolute;
    display: flex;
    width: 45.835%;
    top: 35%;
    transform: translateY(-50%);

    .element {
      width: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;

      p {
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        width: 100%;
        font-family: $fGolos;
        color: $brownishGray;
        margin-bottom: 25px;
      }

      img {
        margin: auto auto;
        width: 25px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
 .container {
   .elementsContainer {
       img {
        width: 20px !important;
      }
     }
   }
}

// @media screen and (min-width: 361px) {
//   .container {
//     width: 340px;
//     margin: 0 auto;

//     .elementsContainer {
//       top: 48px;

//     }
//   }
// }