.container {
  display: flex;
  gap: 16px;

  img {
    border: 2px solid var(--Borders, #27394B);
    border-radius: 12px;
  }

  .legendContainer {
    display: flex;
  }
}

.palmImage {
  height: 105%;
  width: 103%;
}

@media screen and (max-width: 360px) {
  .container {
    flex-direction: column;

    .legendContainer {
      display: grid;
      flex-direction: row;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (min-width: 361px) {
  .container {
    flex-direction: row;
    align-items: center;

    .legendContainer {
      flex-direction: column;
      gap: 12px;
    }
  }

  .palmImage {
    width: 110%;
    height: 112%;
  }
}