@import 'styles/variables';

.zodiacContainer {
  width: 74px;
  height: 74px;
  padding: 16px;
  gap: 10px;
  border-radius: 60px;
  border: 2px solid $borderColor;
  background: $lavender;
}