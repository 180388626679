@import 'src/styles/variables';

.container {
  gap: 6px;

  .title {
    text-align: center;
    font-style: normal;
    line-height: normal;
  }

  .selectedTitle {
    color: $yellow;
  }

  .circle {
    display: flex;
    width: 74px;
    height: 74px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 60px;
    border: 2px solid $borderColor;
    background: $lavender;
  }

  .selectedCircle {
    background: $gradientBlue;
  }

  .selectedBlueCircle {
    background: $gradientBlue;
  }
}