@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  color: $silver;
  cursor: pointer;

  .title {
    text-align: center;
    font-family: $fGolos;
    font-size: 7px;
    line-height: 7px;
    font-style: normal;
    font-weight: 600;
    color: $brownishGray;
    text-transform: uppercase;
  }

  .selectedTitle {
    color: $yellow;
    background: $gradientPink;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .circle {
    display: flex;
    width: 49px;
    height: 49px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 60px;
    border: 2px solid $borderColor;
    background: $lavender;
  }

  .selectedCircle {
    background: $gradientBlue;
  }

  .selectedBlueCircle {
    background: $gradientBlue;
  }
}