@import 'styles/variables';

.bgContainer {
  background: rgba(255, 255, 255, 0.02);
  padding: 40px 0 0;
}

.zodiacContainer {
  width: 60px;
  height: 60px;
}


.infoContainer {
  width: 100px;
  margin: 16px auto 32px;

  .labelsContainer {
    width: 110%;
    bottom: -12px;

    p {
      color: $brownishGray;
      font-family: $fLora;
      text-align: center;
      font-size: 8px;
      text-transform: uppercase;
    }
  }
}

.paddingHorizontal {
  padding-left: 5.33%;
}