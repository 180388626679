@import "src/styles/variables";

.cardHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .colorLabel {
    display: inline-flex;
    padding: 4px 6px;
    align-items: center;
    gap: 10px;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    background: $gradientBlue;
    border-radius: 6px;
    border: 1px solid  $borderColor;
  }
}

.text {
  align-self: stretch;
  color: $silver;
  font-family: $fLora;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $brownishGray;
}

.metricsProgressBar {
  width: 100%;
  margin: 0 auto 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .progressBarWrapper {
    background-color: transparent;
    width: 100%;
  }

  .progressBar > div:first-child {
    height: 2px !important;
  }

  .labelsContainer {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    bottom: -12px;

    p {
      color: $brownishGray;
      text-align: center;
      font-size: 8px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}