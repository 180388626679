@import "src/styles/variables";

.scene {
  display: inline-block;
  width: 100%;
  height:  167px;
  perspective: 300px;
}

.card {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transform-style: preserve-3d;
  transform-origin: center right;
  transition: transform 1s;
}

.card.isFlipped {
  transform: translateX(-100%) rotateY(-180deg);
}

.cardFace {
  position: absolute;
  width: 100%;
  height: 167px;
  backface-visibility: hidden;

  img {
    width: 100%;
    height: 167px;
  }
}

.cardFaceBack {
  transform: rotateY(180deg);
}