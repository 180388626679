@import "styles/variables";


.checkbox-container {
    display: inline-flex;
    align-items: center;
    position: relative;
}

.checkbox-container input[type="checkbox"] {
    display: none;
}

.toast {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    height: 68px;
    justify-content: center;
    align-items: center;
    background: $gradientBlue;
    font-family: $fLora;
    font-size: 18px;
    color: #fff;
}

.checkbox-container .checkmark {
    width: 16px;
    /* Adjust size of the container */
    height: 16px;
    /* Adjust size of the container */
    border: 2px solid transparent;
    background: linear-gradient($lavender, $lavender) padding-box, linear-gradient(70.82deg, $pink -12.56%, $lightPink 102.65%) border-box;
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    position: relative;
}

.custom-modal-header {
    font-family: $fGolos;
    color: $brownishGray;
    font-family: 600;
    font-size: 24px;
    line-height: 28px;
}

date-demo-wrapper {
    flex-direction: row;
    overflow: hidden;
}

.date-flicking {
    overflow: visible;
    box-sizing: content-box;
    height: 150px;
    perspective: 700px;
    position: relative;
    // transform-style: preserve-3d;
    width: 100px;
    text-align: center;
}


.date-flicking>.flicking-camera {
    transform-style: preserve-3d;
}

.date-panel {
    align-items: center;
    height: 20px;
    margin-bottom: 10px;
    backface-visibility: hidden;
    font-size: 18px;
    color: $brownishGray;
    font-weight: 500;
    font-family: $fMontserrat;
}

.date-panel-container {
    z-index: -3;
}

.date-panel-border-1 {
    width: 70px;
    height: 30px;
    position: absolute;
    top: 60px;
    left: 5px;
    border-top: 1px solid $error;
    border-bottom: 1px solid $error;
    z-index: 2;
}

.date-panel-border-2 {
    width: 100px;
    height: 30px;
    position: absolute;
    top: 60px;
    left: 160px;
    border-top: 1px solid $error;
    border-bottom: 1px solid $error;
    z-index: 2;
}



.shadow-top {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 0;
    left: 0;
    background: #E1DEF805;
    z-index: 10;
    backdrop-filter: blur(2px);

}

.shadow-bottom {
    width: 100%;
    height: 25px !important;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #E1DEF805;
    z-index: 10;
    backdrop-filter: blur(2px);

}


.has-text-white {
    color: #fff;
}