@import "styles/variables";

.content {
  color: $silver;
  font-family: $fMontserrat;
  font-style: normal;
  line-height: normal;
  text-align: left;

  .progressBarContainer {
    width: 100px;
    margin: 16px auto 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    h1 {
      margin-bottom: 8px;
    }

    .progressBarWrapper {
      background-color: transparent;
      width: 100%;
    }

    .progressBar {
      background: $borderColor;
      height: 2px;
      width: 100%;
    }

    .progressBar > div:first-child {
      height: 2px !important;
      background-color: #9B7BD0 !important;
    }

    .labelsContainer {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 120%;
      bottom: -10px;

      p {
        color: $brownishGray;
        text-align: center;
        font-size: 8px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

  .elementsContainer {
    margin-bottom: 40px;

    .btnWrapper {
      margin-top: 8px;
      align-items: center;
      text-align: center;
      display: flex;
      justify-content: center;

      .customBtn {
        text-align: center;
        font-family: $fMontserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
      }
    }
  }

  .overviewContainer {
    position: relative;
    display: flex;
    padding: 20px 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 12px;
    border: 2px solid $borderColor;
    background: $lavender;

    h3 {
      text-align: left;
      color: $brownishGray;
    }

    .overviewBtnWrapper {
      margin: 8px auto 0;

      .customBtn {
        text-align: center;
        font-family: $fMontserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
      }
    }
  }

  .luckyDayContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    h3 {
      align-self: flex-start;
    }

    .dateContainer {
      display: flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 4px;

      border-radius: 12px;
      border: 2px solid $borderColor;
      background: $lavender;

      p {
        color: $brownishGray;
        font-family: $fGolos;
        font-size: 14px;
        font-weight: 500;
      }
    }

    p {
      color: $brownishGray;
      font-family: $fLora;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .metricsContainer {
    .metricCard {
      display: flex;
      padding: 18px 17px;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      border-radius: 12px;
      border: 2px solid $borderColor;
      background: $lavender;

      .cardHeader {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .colorLabel {
          display: inline-flex;
          padding: 4px 6px;
          align-items: center;
          gap: 10px;
          font-size: 10px;
          font-weight: 600;
          text-transform: uppercase;
          border-radius: 6px;
          border: 1px solid  $borderColor;
          color: $white;
        }

        .bgGradientBlue{
          background: $gradientBlue;
        }
      }

      .text {
        align-self: stretch;
        color: $brownishGray;
        font-family: $fLora;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .aspectsContainer {
    .aspectsBtnWrapper {
      margin: 8px auto 0;
      display: flex;
      justify-content: center;

      .customBtn {
        text-align: center;
        font-family: $fMontserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
      }
    }
  }

  .relationshipHurdlesContainer {
    .blocksContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      padding-bottom: 40px;
    }
  }
}

.textContainer {
  text-align: justify;
  font-family: $fLora;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  overflow: hidden;
  color: $brownishGray;

  .textGradient {
    position: absolute;
    top: 20px;
    height: 38px;
    width: 100%;
    background: linear-gradient(180deg, rgba(237, 236, 247, 0) 0%, rgba(237, 236, 247, 0.8) 32.81%, #EDECF7 100%);

  }

  .textGradientInCard {
    background: linear-gradient(180deg, rgba(237, 236, 247, 0) 0%, rgba(237, 236, 247, 0.8) 32.81%, #EDECF7 100%);
  }
}

.metricsProgressBar {
  width: 100%;
  margin: 0 auto 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .progressBarWrapper {
    background-color: transparent;
    width: 100%;
  }

  .progressBar {
    background: $borderColor;
    height: 2px;
    width: 100%;
  }

  .progressBar > div:first-child {
    height: 2px !important;
    background: $gradientBlue !important;
  }

  .labelsContainer {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    bottom: -12px;

    p {
      color: $brownishGray;
      text-align: center;
      font-size: 8px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}