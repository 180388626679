@import "src/styles/variables";

.btnContainer {
  display: flex;
  width: 49.6567%;
  padding: 10px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: $brownishGray;
  text-align: center;
  font-family: $fGolos;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
}

.isActive {
  border-radius: 10px;
  background: $gradientBlue;
  color: $white;
}