@import "styles/variables";

.appContainer {
    background-color: $softLavender;
}

.pricingStackCardsContainer {
    background-color: transparent;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, 1fr));
    column-gap: 12px;
    row-gap: 20px;
    margin-top: 30px;
    
    .card {
        height: 92px;
        border: 2px solid $borderColor;
        padding: 0.7rem 8px;
        border-radius: 12px;
        text-align: center;
        flex-wrap: nowrap;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        position: relative;
        cursor: pointer;
    }

    .card:hover {
        background-color: $lavender;
    }

    .most-popular {
        border-color: $blue ;
    }

    .duration {
        font-size: 8px;
        font-weight: 600;
        line-height: 8px;
        color: $brownishGray;
        text-transform: uppercase;
        font-family: $fGolos;

    }

    .price {
        font-size: 24px;
        font-weight: 600;
        line-height: 18px;
        color: $brownishGray;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
        flex-direction: row;
        gap: 1px;
        font-family: $fGolos;
    }

    .decimalPrice {
        text-decoration: underline;
        margin-bottom: 8px;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        color: $brownishGray;

    }

    .priceByDay {
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        color: #C0BDC3;
    }

}


.mostPopular {
    background-clip: padding-box;
    /* !importanté */
    /* !importanté */
    border: solid 2px transparent !important;
    border-radius: 12px !important;
    // background-color: $lavender !important;
    background: linear-gradient($lavender, $lavender) padding-box, linear-gradient(70.82deg, #5784F8 -12.56%, #F5B6FF 102.65%) border-box;


    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     z-index: -1;
    //     margin: -2px -2px -2px -2px;
    //     /* !importanté */
    //     border-radius: inherit;
    //     /* !importanté */
    //     // background: linear-gradient(to right, red, orange);
    //     background: linear-gradient(70.82deg, #5784F8 -12.56%, #F5B6FF 102.65%);
    // }

    .gradientBGMsg {
        font-size: 9px;
        line-height: 9px;
        text-transform: uppercase;
        font-weight: 600;
        white-space: nowrap;
        color: $softLavender;
        display: block !important;
        position: absolute;
        top: -8px;
        left: 50%;
        padding: 4px 6px;
        background: $gradientBlue;
        // border: 1px solid $borderColor;
        translate: -50%;
        border-radius: 6px;
    }
    
    
}


// .save50 {
//     .gradientBGMsg {
//         font-size: 8px;
//         line-height: 8px;
//         font-weight: 600;
//         white-space: nowrap;
//         color: $softLavender;
//         display: block !important;
//         position: absolute;
//         top: -8px;
//         left: 50%;
//         padding: 2px 6px;
//         background: $gradientBlue;
//         border: 1px solid $borderColor;
//         translate: -50%;
//         border-radius: 6px;
//     }
// }

.tastimonialWrapper {
    padding: 0 30px;
    position: relative;
    text-align: left;
    margin-top: 15px;
    font-family: $fLora;
    line-height: 24px;
    font-size: 16px;

    .quotes1 {
        position: absolute;
        top: 2px;
        left: 2px;
    }

    .quotes2 {
        position: absolute;
        right: 2px;
        bottom: 2px;
        transform: rotate(180deg);
    }
}

.joinedPersonsWrapper {
    position: relative;
    overflow: hidden;
    background-color: transparent;
    margin: 60px 0 30px 0;
    width: 100%;
    height: 180px;

    .joinedPersonsClipped1,
    .joinedPersonsClipped2 {
        background-color: $lavender;
        border: 2px solid $borderColor;
        white-space: nowrap;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        color: $brownishGray;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        max-width: 120%;

        position: absolute;
        padding: 6px;
        left: -20px;
        top: 0;
        clip-path: inset(0);

        img {
            width: 30px;
            height: 30px;
        }

    }

    .joinedPersonsClipped1 {
        rotate: 12deg;
        transform-origin: left center;
    }

    .joinedPersonsClipped2 {
        rotate: -12deg;
        transform-origin: right center;
    }

}

.moneyBackGuaranteeCard {
    position: relative;
    padding: 1rem;
    // background: $appBg;
    // border: 2px solid $lavender;
    border: 2px solid transparent;
    background: linear-gradient($softLavender, $softLavender) padding-box, linear-gradient(70.82deg, #5784F8 -12.56%, #F5B6FF 102.65%) border-box;
    border-radius: 12px;
    margin-bottom: 40px;
    z-index: 1;
}


.stickedFadedGuranteeBadge {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

