@import "src/styles/variables";

.content {
  color: $silver;
  font-family: $fMontserrat;
  font-style: normal;
  line-height: normal;
  text-align: left;

  .cardsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.388%;

    img {
      height: 126.653px;
    }
  }


}