@import "styles/variables";

.progress-bar-wrapper {
  border-radius: 2px;
  background: $borderColor;
  width: 100%;
}

.progress-bar-container {
  background: $borderColor;
  height: 2px;
  width: 100%;
}

.progress-bar-container > div:first-child {
  height: 2px !important;
}

.progress-bar-container-electric-blue > div:first-child {
  background: $gradientPink !important;
}

.progress-bar-purple > div:first-child {
  background-color: $regularPurple !important;
}

.progress-bar-secondary > div:first-child {
  background-color: $secondary !important;
}

.progress-bar-muted-gold > div:first-child {
  background-color: $mutedGold !important;
}
.progress-bar-gradient-blue > div:first-child {
  background: $gradientBlue !important;
}

.label {
  font-size: 20px;
  color: green;
}