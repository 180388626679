.circle {
  border: 1px solid var(--Borders, #d3d3d3);
  background: var(--Primary, #E1DEF805);
  width: 204px;
  height: 204px;
  border-radius: 200px;
  padding: 32px;
  position: absolute;
  top: 0;
  z-index: -1;
}

.progressBarWrapper {
  height: 6px;
  border-radius: 6px;
}