@import "styles/variables";

.timeline-container {
  width: 100%;
  height: 50px;
  position: relative;
}

.timeline-line {
  width: 100%;
  height: 2px;
  background-color: $borderColor;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.timeline-point {
  width: 5px;
  height: 5px;
  background-color: $yellow;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.timeline-title {
  border-radius: 50%;
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
}