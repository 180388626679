.react-modal-sheet-backdrop {
  /* custom styles */
  background-color: transparent !important;
}
.react-modal-sheet-container {
  border-radius: 30px 30px 0px 0px;
  border-top: 2px solid var(--Borders, #27394B);
  border-right: 2px solid var(--Borders, #27394B);
  border-left: 2px solid var(--Borders, #27394B);
  backdrop-filter: blur(20px);
  padding: 32px 20px 0;
  background-color: rgba(18, 33, 51, 0.80) !important;
  border-top-right-radius: 18px !important;
  border-top-left-radius: 18px !important;
  left: initial !important;
  /* custom styles */
}
.react-modal-sheet-drag-indicator {
  /* custom styles */
}

.react-modal-sheet-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.react-modal-sheet-content {
  border-top-right-radius: 18px !important;
  border-top-left-radius: 18px !important;
}

@media screen and (max-width: 430px) {
  .react-modal-sheet-container {
    max-width: 430px;
  }
};

@media screen and (min-width: 431px) {
  .react-modal-sheet-container {
    max-width: 393px;
  }
};