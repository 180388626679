@import 'src/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  color: $silver;
  cursor: pointer;

  .title {
    text-align: center;
    font-family: $fMontserrat;
    font-size: 6px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  .selectedTitle {
    color: $yellow;
  }

  .circle {
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 60px;
    border: 2px solid $borderColor;
    background: $softLavender;
  }
}