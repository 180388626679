$main-bg: rgb(247, 247, 247);

$primary: #122133;
$white: #FFFFFF;
$silver: #E5E5E5;
$gray: #4C556F;
$yellow: #FFD700;
$electricBlue: #7DF9FF;
// $borderColor: #27394B;
$regularPurple: #9B7BD0;
// $disabled: #58A1A5;
$disabled: #CDCDCD;
$secondary: #5A8C8D;
$green: #6B8E23;
$red: #CD5C5C;
$blue: #4169E1;
$orange: #F2AC3C;
$violet: #8E4BF6;
$royalBlue: #3F5497;
$mutedGold: #746C20;
$subtitleColor: #A5A5A5;

// $elBg: #172536;
$elBg: #172536;
$inputBg: rgba(23, 37, 54, 0.02);

// new variables
 $softLavender: #EDECF7;
 $lavender: #E1DEF8;
 $gradientPink: linear-gradient(47.6deg, #F86557 -11.64%, #FFB6C1 161.65%);
 $brownishGray: #827677;
 $borderColor: #D3D3D3;
 $appBg: #E1DEF805;
 $pink: #F86557;
 $lightPink: #FFB6C1;
 $gradientBlue: linear-gradient(70.82deg, #5784F8 -12.56%, #F5B6FF 102.65%);
 $homeGradientBG: linear-gradient(70.82deg, rgba(87, 133, 248, 0.501) -12.56%, rgba(245, 182, 255, 0.507) 102.65%);
 $error: #FE6363;
 $gray100: #f3f4f6;
 $gray200: #e5e7eb;
 $gray300: #d1d5db;
 $gray400: #9ca3af;
 $gray500: #6b7280;


/*---Layout ---*/
$grid-padding: 28px;

/* fonts*/
$fMontserrat: 'Montserrat', sans-serif;
$fRoboto: 'Roboto', sans-serif;
$fPapyrus: 'Papyrus', sans-serif;
$fLora: "Lora", serif;
$fGolos:  "Golos Text", sans-serif;

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 400 to 900

  



// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 400 to 700




