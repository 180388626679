@import "styles/variables";

.flicking-pagination-bullet {
  width: 9px;
  height: 9px;
  background-color: $borderColor;
  margin: 0 3px;
  transition: 0.3s all ease-in-out;
}

.flicking-pagination-bullet-active {
  width: 13px;
  height: 13px;
  background-color: #F86557;
}

.flicking-viewport {
  overflow: initial;
}

.flicking-pagination {
  bottom: -48px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.flicking-camera {
  gap: 16px;
  padding: 0 calc(5.33% - 7.1422px);
}