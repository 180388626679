@import "styles/variables";

.date-demo-wrapper {
  flex-direction: row;
  overflow: hidden;
}

.date-flicking {
  overflow: visible;
  box-sizing: content-box;
  height: 150px;
  perspective: 700px;
  position: relative;
  // transform-style: preserve-3d;
  width: 100px;
}


.date-flicking>.flicking-camera {
  transform-style: preserve-3d;
}

.date-panel {
  align-items: center;
  height: 20px;
  margin-bottom: 10px;
  backface-visibility: hidden;
  font-size: 18px;
  color: $brownishGray;
  font-weight: 500;
  font-family: $fMontserrat;
}

.date-panel-container {
  z-index: -3;
}

.date-panel-border-1 {
  width: 50px;
  height: 30px;
  position: absolute;
  top: 60px;
  border-top: 1px solid $error;
  border-bottom: 1px solid $error;
  z-index: 2;
}

.date-panel-border-2 {
  width: 100px;
  height: 30px;
  position: absolute;
  top: 60px;
  left: 100px;
  border-top: 1px solid $error;
  border-bottom: 1px solid $error;
  z-index: 2;
}

.date-panel-border-3 {
  width: 70px;
  height: 30px;
  position: absolute;
  top: 60px;
  left: 240px;
  border-top: 1px solid $error;
  border-bottom: 1px solid $error;
  z-index: 2;
}



.shadow-top {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  background: #E1DEF805;
  z-index: 10;
  backdrop-filter: blur(2px);

}

.shadow-bottom {
  width: 100%;
  height: 25px !important;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #E1DEF805;
  z-index: 10;
  backdrop-filter: blur(2px);

}


.has-text-white {
  color: #fff;
}