@import "styles/variables";

.content {
  font-style: normal;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  margin-top: 32px;

  h1 {
    text-align: left;
  }
}

.numbers {
  font-family: $fPapyrus;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 8px;
  background: $gradientBlue;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}