@import 'src/styles/variables';

.purchasedItemOverlay{
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.8); 
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $fGolos;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}