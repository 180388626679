@import "styles/variables";

.infographicContainer {
  border-radius: 12px;
  border: 2px solid $borderColor;
  background: $lavender;
  padding: 10px;
  width: 33.3%;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .title {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    font-family: $fGolos;  
    color: $pink;  
    cursor: pointer;
  }

  .pieContainer {
    width: 50px;
    height: 50px;
    border: 2px solid #a1a1aa;
    border-radius: 25px;
    background-color: $softLavender;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      position: absolute;
      font-size: 14px;
      font-weight: 500;
      color: $brownishGray;
      font-family: $fGolos;
    }

    .container {
      width: 44px;
      height: 44px;
    }
  }
}