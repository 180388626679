@import "src/styles/variables";

.content {
  color: $silver;
  font-family: $fMontserrat;
  font-style: normal;
  line-height: normal;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
}