@import "src/styles/variables";



.card {
  width: 50%;
  height: 192px;
  border-radius: 12px;
  background-color: $appBg;
  backdrop-filter: blur(2px);
  border: 3px solid $borderColor;
  padding: 16px 0;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  justify-content: center;
  display: flex;
  transition: 0.15s all ease-in-out;

  &:hover {
    background: $lavender;
    border: 3px solid $borderColor;
  }

  .circle {
    display: flex;
    width: 90px;
    height: 90px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 60px;
    border: 2px solid $borderColor;
    background: $gradientBlue;
    color: $white;
  }

  &Selected {
    background: $lavender;
    border: 3px solid $pink;
  }
  &Selected:hover {
    background: $lavender;
    border: 3px solid $pink;
  }

  .text{
    font-family: $fGolos;
    font-size: 20px;
    font-weight: 500;
    color: $brownishGray;
  }
}