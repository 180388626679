@import "src/styles/variables";


.textContainer {
  // text-align: justify;
  font-family: $fGolos;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  color: $brownishGray;

text-align: left;
  .textGradient {
    position: absolute;
    top: 35px;
    height: 38px;
    width: 100%;
    background: linear-gradient(180deg, rgba(237, 236, 247, 0.00) 0%, rgba(237, 236, 247, 0.80) 32.81%, rgba(237, 236, 247, 100.00));
  }

  .textGradientInCard {
    background: linear-gradient(180deg, rgba(23, 37, 54, 0.00) 0%, rgba(23, 37, 54, 0.80) 42.71%, #172536 100%);
  }
}

.btnWrapper {
  margin-top: 8px;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}

.customBtn {
  text-align: center;
  font-family: $fMontserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}