@import "styles/variables";

.inputWrapper {
  border: 1px solid $borderColor;
  background:  transparent;
  padding: 10px 10px;
  border-radius: 12px;
  width: 100%;
  color: $brownishGray;
  caret-color: $brownishGray;

}