@import "styles/variables";

.card {
  height: 93px;
  border-radius: 14px;
  background: $inputBg;
  backdrop-filter: blur(3px);
  width: 100%;
  margin: 16px 0;
  justify-content: space-between;

  &Selected {
    border: 2px solid $electricBlue;
    background: $inputBg;
    box-shadow: 0 0 8px 0 #F86557;
    backdrop-filter: blur(3px)
  }
}

.rightContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100px;
  height: 100%;
  border-radius: 11px;
  background: #27394B;
  color: $silver;
  font-family: $fMontserrat;
  line-height: normal;
  font-style: normal;
  font-weight: 600;

  .price {
    font-size: 22px;

    &Selected {
      color: $primary;
    }
  }

  .period {
    font-size: 8px;
    text-transform: uppercase;

    &Selected {
      color: $primary;
    }
  }

  &Selected {
    background: $electricBlue;
  }
}

.leftContainer {
  text-align: left;
  padding: 16px;


  .oldPrice {
    margin-left: 8px;
    text-decoration: line-through;
    text-decoration-color: white;
    text-decoration-thickness: 1px;
  }
}

.label {
  border-radius: 6px;
  border: 1px solid $borderColor;
  background: $yellow;
  padding: 4px 6px;
  color: #122133;
  left: 24px;
  top: -10px;

  &Selected {
    box-shadow: 0px -3px 8px -2px #F86557;
  }
}

.gradientBGMsg {
  font-size: 9px;
  line-height: 9px;
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;
  color: $softLavender;
  display: block !important;
  position: absolute;
  top: -8px;
  left: 50%;
  padding: 4px 6px;
  background: $gradientBlue;
  // border: 1px solid $borderColor;
  translate: -50%;
  border-radius: 6px;
}