.flicking-viewport {
  overflow: initial;

  .flicking-camera {
    gap: 10px;
  }
}

.flicking-pagination {
  bottom: -48px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}