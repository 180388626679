@import 'styles/variables';

.cardHeader {
  width: 100%;

  .colorLabel {
    display: inline-flex;
    padding: 3px 12px;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid  $borderColor;
    background: $gradientBlue;
    color: $white;
  }
}

.textContainer {
  text-align: justify;
  overflow: hidden;

  .textGradient {
    position: absolute;
    top: 20px;
    height: 38px;
    width: 100%;
    background: linear-gradient(180deg, rgba(225, 222, 248, 0) 0%, rgba(225, 222, 248, 0.8) 42.71%, #E1DEF8 100%);
  }

  .textGradientInCard {
    background: linear-gradient(180deg, rgba(225, 222, 248, 0) 0%, rgba(225, 222, 248, 0.8) 42.71%, #E1DEF8 100%);
  }
}

.overviewBtnWrapper {
  margin: 8px auto 0;
}