@import "src/styles/variables";

.rotating-astro-circle-aries {
  animation: rotate-aries 2s ease-in-out;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}



@keyframes rotate-aries {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}