
.cardsContainer {

  img {
    width: 30.885%;
    position: absolute;
  }
}

.cardsContainer img:nth-child(1) {
  left: 0;
}

.cardsContainer img:nth-child(2) {
  left: 9.796%;
}

.cardsContainer img:nth-child(3) {
  left: 19.592%;
}

.cardsContainer img:nth-child(4) {
  left: 29.388%;
}
.cardsContainer img:nth-child(5) {
  left: 39.184%;
}
.cardsContainer img:nth-child(6) {
  left: 48.98%;
}
.cardsContainer img:nth-child(7) {
  left: 58.776%;
}
.cardsContainer img:nth-child(8) {
  right: 0;
}

.moveUp {
  transform: translateY(-16px);
}