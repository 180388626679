@import "styles/variables";

.content {
  font-style: normal;
  line-height: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  margin-top: 32px;

  h1 {
    text-align: left;
  }
}

.colorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  p {
    color: $brownishGray;
    font-family: $fGolos;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  .circle {
    display: flex;
    width: 80px;
    height: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 60px;

    border: 2px solid $borderColor;
  }
}