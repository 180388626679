.invisible {
    opacity: 0.2;
}

.note {
    font-style: italic;
    font-size: 130%;
}

.highlighter {
    background: rgba(0, 255, 0, 0.25);
    border: 1px dashed #fff;
    z-index: 1;
    position: absolute;
}

.canvas {
    z-index: 1;
    position: absolute;
    pointer-events: none;
}

.output_canvas {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
}

.detectOnClick {
    z-index: 0;
}

.detectOnClick img {
    width: 100%;
}

.animation-div {
    position: absolute;
    top: 0;
    width: 100%;
    height: 35px;
    background: linear-gradient(180deg, rgba(125, 249, 255, 0.00) 0%, #F86557 50%, rgba(125, 249, 255, 0.00) 100%);
    animation: moveUpDown 5s ease-in-out infinite alternate;
    z-index: 2000;
}

@keyframes moveUpDown {
    0% {
        top: 0;
    }
    50% {
        top: calc(100% - 35px); 
    }
    100% {
        top: 0;
    }
}