@import "styles/variables";

.card {
  width: 100%;
  height: 60px;
  border-radius: 12px;
  background-color: $inputBg;
  backdrop-filter: blur(3px);
  border: 2px solid $borderColor;
  padding: 12px 16px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  display: flex;

  

  &:not(.cardSelected):hover {
    background: $lavender;
    border-color: $borderColor;  }


    &.cardSelected {
      border: 2px solid transparent;
      background: linear-gradient($lavender, $lavender) padding-box, linear-gradient(70.82deg, #5784F8 -12.56%, #F5B6FF 102.65%) border-box;
    }
}

.text{
  font-family: $fGolos;
  font-weight: 600;
  color: $brownishGray;
  
}