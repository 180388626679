@import 'styles/variables';

.cardsContainer {
  height: 167px;
  gap: 3.582%;
}

.fortuneCookieContainer {
  border-radius: 20px;
  border: 2px solid $regularPurple;
  background: $lavender;
  padding: 24px 18px;
  backdrop-filter: blur(2px);

  button {
    font-weight: 500;
    justify-content: flex-start;
    svg {
      margin-left: 6px;
    }
  }
}

.itemContainer {
  width: 29.85%;
  height: 85px;
  background: $lavender;
}

.absoluteLeft8 {
  left: 8px;
  width: 99.905px;
  height: 115.673px;
}

.absoluteRight8 {
  right: 8px;
  width: 99.905px;
  height: 115.673px;
}


