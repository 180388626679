@import 'styles/variables';

.container {
  font-style: normal;
  line-height: normal;

  .imgContainer {
    width: 100px;
    display: flex;
    height: 85px;
    padding: 11px 22.5px 10.63px 22.5px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 2px solid $borderColor;
    background: $lavender;
  }
}