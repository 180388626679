.opacity-out {
  animation: opacity-out-animation 1s ease-in-out;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.line-stars-out {
  animation: scale-and-opacity-out-animation 1s ease-in-out;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.opacity-in {
  animation: opacity-in-animation 1s ease-in;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.display-none {
  display: none;
}

@keyframes opacity-out-animation {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes scale-and-opacity-out-animation {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes opacity-in-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}