@import "styles/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 0 20px;
  color: $silver;

  .title {
    text-align: center;
    font-family: $fMontserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    color: $brownishGray;
  }

  .selectedTitle {
    color: $pink;
  }

  .circle {
    cursor: pointer;
    display: flex;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 60px;
    border: 2px solid $borderColor;
    background: $gradientBlue;

    img {
      width: 55px;
      height: 55px;
    }
  }

  .secondBlueCircle {
    background: $gradientBlue;
  }
}