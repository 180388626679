@import "src/styles/variables";

.card {
  display: flex;
  width: 120px;
  height: 120px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 60px;
  border: 2px solid $borderColor;
  background: $inputBg;
  backdrop-filter: blur(3px);

  // Hover state when not selected
  &:not(.cardSelected):hover {
    background: $lavender;
    border-color: $borderColor;  }

  // Selected state
  &.cardSelected {
    border: 2px solid transparent;
    background: linear-gradient($lavender, $lavender) padding-box, linear-gradient(70.82deg, #5784F8 -12.56%, #F5B6FF 102.65%) border-box;
  }
}





.text {
  font-family: $fGolos;
  color: $brownishGray;
  font-weight: 700;
}

.cardsContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  flex-wrap: wrap;
  margin-top: 39px;
}

.circle {
  width: 36px;
  height: 36px;
  border-radius: 60px;
  border: 2px solid $borderColor;
  background: #F2AC3C;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}