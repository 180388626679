.date-demo-wrapper {
  flex-direction: row;
  overflow: hidden;
}

.date-flick {
  overflow: visible;
  box-sizing: content-box;
  height: 260px;
  perspective: 300px;
  transform-style: preserve-3d;
}

.date-flick>.flicking-camera {
  transform-style: preserve-3d;
}

.date-panel {
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
  backface-visibility: hidden;
}

.date-panel-border2 {
  width: calc(100% - 20px);
  height: 38px;
  position: absolute;
  top: 102px;
  left: 14px;
  border-radius: 8px;
  border: 2px solid #FFD700;
  z-index: -3;
}

.shadow-top {
  width: 100%;
  height: 88px;
  position: absolute;
  top: 0;
  left: 0;
  // background: linear-gradient(180deg, rgba(23, 37, 54, 0.02) 0%, rgba(23, 37, 54, 0.00) 100%);
  backdrop-filter: blur(1px);
}

.shadow-bottom {
  width: 100%;
  height: 112px;
  position: absolute;
  bottom: 0;
  left: 0;
  // background: linear-gradient(180deg, rgba(23, 37, 54, 0.02) 0%, rgba(23, 37, 54, 0.00) 100%);
  backdrop-filter: blur(1px);
}

.has-text-white {
  color: #fff;
}