.width350 {
  width: 350px;
}

.line {
  border-bottom: 1px solid #c1c3c6;
  width: 100%;
}

.ReactModal__Overlay {
  //opacity: 0;
  z-index: 100;
  //transition: opacity 1000ms ease-in-out;
}
//
//.ReactModal__Overlay--after-open{
//  opacity: 1;
//}
//
//.ReactModal__Overlay--before-close{
//  opacity: 0;
//}

//.custom-overlay {
//  opacity: 1; /* Initial opacity */
//  transition: opacity 500ms ease-in-out;
//  z-index: 100; /* Ensure overlay is above other content */
//}
//
//.custom-modal.ReactModal__Content {
//  transform: translateY(100%); /* Initial position (offscreen bottom) */
//  transition: transform 10000ms ease-in-out;
//}
//
//.custom-modal.ReactModal__Content--after-open {
//  transform: translateY(0); /* Animate to center */
//  opacity: 1;
//}
//
//.custom-modal.ReactModal__Content--before-close {
//  transform: translateY(100%); /* Animate back to bottom */
//}