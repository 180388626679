@import "styles/variables";

.container {
  color: $brownishGray;

  .selectedTitle {
    background: $gradientPink;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .circle {
    border-radius: 60px;
    border: 2px solid $borderColor;
    background: $lavender;
  }

  .secondBlueCircle {
    background: $lavender;
  }
}