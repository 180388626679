@import "src/styles/variables";

.content {
  color: $silver;
  font-family: $fMontserrat;
  font-style: normal;
  line-height: normal;
  text-align: left;

  .btnsContainer {
    display: flex;
    padding: 4px 0;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 2px solid $borderColor;
    background: $primary;
  }

  .radarContainer {
    padding: 32px 0 12px;
    display: flex;
    justify-content: center;
  }

}