@import "src/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .header {
    display: flex;
    align-items: center;
    gap: 4.776%;
  }
}